<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Advertising from  "@/services/Advertising";
import Swal from "sweetalert2";
import modalAddSponsoredPlayer from "@/components/modals/advertising/modalAddSponsoredPlayer";

export default {
  components: { Layout, PageHeader, modalAddSponsoredPlayer },
  page: {
    title: "Sponsored Player",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
        title: "Sponsored Player",
        items: [
            {
                text: "Advertising",
                href: "/",
            },
            {
                text: "Sponsored Player",
                active: true,
            },
        ],
        tableData: [],
        error: null,

        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "round",
        sortDesc: false,
        noResultsText: 'No Data Found',
        fields: [
            {
              key: "player_name",
              label: "Name",
              sortable: true,
            },
            {
              key: "player_position",
              label: "Position",
              sortable: true,
            },
            {
              key: "player_shirt",
              label: "Shirt Number",
              sortable: true,
            },
            {
              key: "player_image",
              label: "Image",
              sortable: true,
            },
            {
              key: "player_team",
              label: "Team",
              sortable: true,
            },
            {
              key: "sponsor_name",
              label: "Sponsor Name",
              sortable: true,
            },
            {
              key: "media_source_url",
              label: "Media Image",
              sortable: true,
            },
            {
              key: "media_type",
              label: "Media Type",
              sortable: true,
            },
            {
              key: "media_width",
              label: "Width",
              sortable: true,
            },
            {
              key: "media_height",
              label: "Height",
              sortable: true,
            },
            {
              key: "link_url",
              label: "Link Url",
              sortable: true,
            },
            
            "action",
        ],
        csrf_token: localStorage.getItem('csrf_token')
    };
  },
  middleware: "authentication",
  computed: {
      /**
       * Total no. of records
       */
      rows() {
        return this.tableData.length;
      },
  },
  mounted() {
    // Set the initial number of items
    //this.totalRows = this.items.length;
  },
  created() {
      this.getSponsoredPlayers()
  },
  methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        getSponsoredPlayers(){
            Advertising.getSponsoredPlayers()
            .then(response => {
                //console.log(response)
                this.tableData = response.data.data;
            })
            .catch(error => {
                this.tableData = [];
                this.error = error.response.data.error ? error.response.data.error : "";
            })
        },

        removeFromSponsored(player_id, media_id) {
          Swal.fire({
              title: "Are you sure?",
              text: "This Player will no longer be listed as Sponsored !",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: "Yes, remove it!",
          }).then((result) => {
              if (result.value) {
                    Advertising.detachSponsoredPlayer({
                        player_id: player_id,
                        media_id: media_id,
                        csrf_token: this.csrf_token
                    })
                    .then((response) => {
                        const res = response.data ? response.data : false;
                        const error = response.data.error ? response.data.error : 'Failed';
                        if(res){
                            this.getSponsoredPlayers();
                            Swal.fire("Removed!", "Player no longer listet as sponsored !", "success");
                        }else{
                            Swal.fire("Fail!", error, "warning");
                        }
                    })
                    .catch(error => {
                        this.getSponsoredPlayers();
                        Swal.fire("Fail!", error, "warning");
                    });
              }
          });
      },

      modalAddSponsoredPlayer() {
          this.$bvModal.show("add_sponsored_player");
      },
      

  },
};

</script>



<template>
    <Layout>
      <PageHeader :title="title" :items="items" />
  
      <div class="row">
        <div class="col-12">
          <div>
              <button type="button" class="btn btn-success mb-3" @click="modalAddSponsoredPlayer()">
                  <i class="mdi mdi-plus me-1"></i> Add New Sponsored Player
              </button>
          </div>
        </div>
      </div>
  
  
      <div class="row">
        <div class="col-12">
          
          <div class="table table-centered datatable dt-responsive nowrap table-card-list dataTable no-footer dtr-inline">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center fw-normal">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center fw-normal">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            
            </div>
            <!-- Table -->
  
            <b-table
              table-class="table table-centered datatable table-card-list"
              thead-tr-class="bg-transparent"
              :items="tableData"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              show-empty
              empty-text="No Data Found"
              empty-filtered-text="No Data Found"
            >
                <template v-slot:cell(player_name)="data">
                    <p>{{data.item.player.first_name}} - {{data.item.player.last_name}}</p>
                </template>
                <template v-slot:cell(player_position)="data">
                    <p>{{data.item.player.function}}</p>
                </template>
                <template v-slot:cell(player_shirt)="data">
                    <p>{{data.item.player.shirt_number}}</p>
                </template>
                <template v-slot:cell(player_image)="data">
                    <img class="img-fluid" width="100" :src="data.item.player.img" >
                </template> 
                <template v-slot:cell(player_team)="data">
                    <p>{{data.item.player.team}}</p>
                </template>
                <template v-slot:cell(sponsor_name)="data">
                    <p>{{data.item.sponsor.name}}</p>
                </template>
                <template v-slot:cell(media_source_url)="data">
                    <img class="img-fluid" width="100" :src="data.item.media_source_url" >
                </template> 
                <template v-slot:cell(link_url)="data">
                    <a :href="data.item.link_url" target="_blank">{{data.item.link_url}}</a>
                </template>
    
                <template v-slot:cell(action)="data">
                    <ul class="list-inline mb-0">
                        <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Remove from Sponsored" @click="removeFromSponsored(data.item.player_id, data.item.media_id)">
                            <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                    </ul>
                </template>

            </b-table>

          </div>

          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  
  
       <!-- MODALS --> 
       <modalAddSponsoredPlayer @refreshTable="getSponsoredPlayers" />

      <!-- END  MODALS --> 
  
  
    </Layout>
  </template>
  